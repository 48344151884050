<nav class="layout-breadcrumb">
    <ol>
        <ng-template ngFor let-item let-last="last" [ngForOf]="breadcrumbs$ | async">
            <li *ngIf="!last"><a [routerLink]="item.url" style="font-size: 18px;">{{ item.label }}</a></li>
            <li *ngIf="last" style="font-size: 18px;">{{ item.label }}</li>
            <li *ngIf="!last" class="layout-breadcrumb-chevron">/</li>
        </ng-template>
    </ol>
</nav>


<!--<nav class="layout-breadcrumb">
    <ol>
        <ng-template ngFor let-item let-last="last" [ngForOf]="breadcrumbs$ | async">
            <li>{{item.label}}</li>
            <li *ngIf="!last" class="layout-breadcrumb-chevron"> / </li>
        </ng-template>
    </ol>
</nav>-->