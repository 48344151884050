<div class="layout-sidebar">
    <a [routerLink]="['/apps/dashboard']" class="app-logo">

        <svg width="240" height="40" viewBox="0 0 124 22" fill="none" class="app-logo-normal" version="1.1" id="svg9"
            xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
            <defs id="defs9" />
            <g id="g5" transform="matrix(0.09117155,0,0,0.09205985,15.252985,3.8593581)">
                <path id="path2"
                    d="M 118.21712,70.258809 96.845121,32.627372 118.16742,-5.0332752 160.86171,-5.0624899 182.2337,32.568942 160.91141,70.229594 Z"
                    style="fill:#0f75bd;fill-opacity:1;stroke-width:0.250139;stroke-miterlimit:4;stroke-dasharray:3.00167, 0.250139;stroke-dashoffset:0"
                    transform="translate(-110.61967,2.660139)" />
                <path id="path1"
                    d="M 40.934491,116.30005 19.562496,78.668633 40.884792,41.00798 83.579084,40.978766 104.95103,78.610203 83.628783,116.27083 Z"
                    style="fill:#da251d;fill-opacity:1;stroke-width:0.250139;stroke-miterlimit:4;stroke-dasharray:3.00167, 0.250139;stroke-dashoffset:0"
                    transform="translate(-110.61967,2.660139)" />
                <path id="path3"
                    d="m 120.07093,157.875 -21.372011,-37.63144 21.322311,-37.660643 42.69429,-0.02921 21.37199,37.631423 -21.32229,37.66066 z"
                    style="fill:#00923f;fill-opacity:1;stroke-width:0.250139;stroke-miterlimit:4;stroke-dasharray:3.00167, 0.250139;stroke-dashoffset:0"
                    transform="translate(-110.61967,2.660139)" />
            </g>
            <g id="text1"
                style="font-weight:900;font-size:20.6148px;font-family:Geometria;-inkscape-font-specification:'Geometria, Heavy';letter-spacing:0px;fill:#0f75bd;stroke-width:1.71791"
                transform="matrix(0.96885734,0,0,1.0321437,2.3738735,-0.69819818)" aria-label="AdmiHair">
                <path
                    d="M 26.756221,18.86614 H 22.179735 L 28.116798,4.3533215 h 4.226034 L 38.300509,18.86614 H 33.53849 l -1.03074,-2.700538 h -4.700174 z m 3.401442,-9.4415778 -1.07197,3.4220568 h 2.123325 z m 15.935255,0 V 4.4357807 h 4.349723 V 18.86614 h -4.349723 v -1.195658 q -0.968896,1.463651 -2.906687,1.463651 -2.02025,0 -3.360212,-1.58734 -1.339962,-1.587339 -1.339962,-3.999271 0,-2.411931 1.339962,-3.999271 1.339962,-1.5873396 3.360212,-1.5873396 1.937791,0 2.906687,1.4636508 z m 0.06184,5.3392328 v -2.411931 q -0.47414,-0.783363 -1.628569,-0.783363 -0.783362,0 -1.278118,0.577215 -0.47414,0.556599 -0.47414,1.401806 0,0.845207 0.47414,1.422421 0.494756,0.5566 1.278118,0.5566 1.175044,0 1.628569,-0.762748 z M 56.833231,18.86614 H 52.504123 V 8.2289038 h 4.329108 v 1.3811916 q 0.391681,-0.6596736 1.257503,-1.1544288 0.865821,-0.4947552 1.814102,-0.4947552 1.999635,0 2.844842,1.7110284 0.432911,-0.7421328 1.360577,-1.2162732 0.948281,-0.4947552 1.999636,-0.4947552 1.54611,0 2.638694,1.0925844 1.07197,1.0719692 1.07197,3.1746792 v 6.637965 h -4.349723 v -5.833988 q 0,-1.298732 -0.948281,-1.298732 -0.762748,0 -1.175044,0.618444 v 6.514276 h -4.349722 v -5.833988 q 0,-1.298732 -0.948281,-1.298732 -0.742133,0 -1.216273,0.659673 z m 19.274834,0 H 71.758343 V 8.2289038 h 4.349722 z M 75.675154,7.0332455 q -0.700903,0.7009031 -1.731643,0.7009031 -1.03074,0 -1.731643,-0.7009031 -0.700903,-0.7009032 -0.700903,-1.752258 0,-1.0101252 0.700903,-1.7110284 0.721518,-0.7215179 1.731643,-0.7215179 0.989511,0 1.711029,0.7215179 0.721518,0.721518 0.721518,1.7110284 0,1.03074 -0.700904,1.752258 z"
                    id="path5" />
                <path style="font-weight:500;-inkscape-font-specification:'Geometria, Medium'"
                    d="M 81.282397,18.86614 H 79.014769 V 4.4357807 h 2.267628 v 5.6896843 h 7.524401 V 4.4357807 h 2.267628 V 18.86614 h -2.267628 v -6.65858 h -7.524401 z m 22.676293,-1.731643 q 0.32984,0 0.59783,-0.144303 v 1.690413 q -0.45352,0.329837 -1.29873,0.329837 -1.73164,0 -2.12333,-1.443036 -1.381188,1.566725 -3.731275,1.566725 -1.628569,0 -2.721153,-0.907051 -1.07197,-0.886437 -1.07197,-2.494391 0,-1.607955 1.175044,-2.473776 1.154429,-0.886436 2.886072,-0.886436 1.834717,0 3.236522,0.783362 v -0.5566 q 0,-2.205783 -2.96853,-2.205783 -1.731643,0 -3.195294,0.907051 V 9.342103 q 1.504881,-0.8658216 3.690049,-0.8658216 2.143935,0 3.401445,1.03074 1.27811,1.0101256 1.27811,2.8448426 v 3.772508 q 0,0.47414 0.22677,0.742133 0.24738,0.267992 0.61844,0.267992 z m -3.05099,-1.133814 v -1.236888 q -1.278116,-0.721518 -2.700537,-0.721518 -1.1132,0 -1.752258,0.412296 -0.618444,0.412296 -0.618444,1.154429 0,0.783362 0.577214,1.195658 0.597829,0.412296 1.607954,0.412296 0.824592,0 1.607955,-0.329836 0.783366,-0.350452 1.278116,-0.886437 z m 8.26654,2.865457 h -2.2264 V 8.7442738 h 2.2264 z m -1.1132,-11.9359685 q -0.59783,0 -1.01013,-0.3916812 -0.39168,-0.412296 -0.39168,-1.0101252 0,-0.5978292 0.39168,-1.0101252 0.4123,-0.412296 1.01013,-0.412296 0.57721,0 0.96889,0.412296 0.4123,0.412296 0.4123,1.0101252 0,0.5978292 -0.4123,1.0101252 -0.39168,0.3916812 -0.96889,0.3916812 z m 6.69982,11.9359685 h -2.2264 V 8.7442738 h 2.2264 v 1.7522582 q 0.51537,-0.8864366 1.54611,-1.4430362 1.03074,-0.5772144 2.18517,-0.5772144 0.1443,0 0.20615,0.020615 v 2.2263986 q -0.37107,-0.04123 -0.61845,-0.04123 -1.13381,0 -2.04086,0.577214 -0.90705,0.577215 -1.27812,1.401807 z"
                    id="path6" />
            </g>
            <rect style="fill:none;fill-opacity:1;stroke-width:1.00018" id="rect6" width="115.62162" height="20.108107"
                x="4.4684687" y="0.69819832" />
        </svg>


    </a>

    <div class="layout-menu-container">
        <app-menu></app-menu>
    </div>
</div>