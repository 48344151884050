import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MenuServiceProjeto } from '../admihair/service/menu.service';
import { MenuDTO } from '../admihair/api/dto/menu-dto';
import { UtilStorageService } from '../admihair/service/util-storage.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    styleUrls: ['./app.menu.component.scss']
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];
    menusFranquia?: MenuDTO[] = [];
    menusEmpresa?: MenuDTO[] = [];
    menuObj: any[] = [];
    menuItemObj: any[] = [];
    menuComppleto: any[] = [];
    items: any[] = [];
    loadingMenus: boolean = true;

    constructor(private menuService: MenuServiceProjeto,
        private utilStorageService: UtilStorageService) { }


    ngOnInit() {
        const acesso = this.utilStorageService.getUser();

        if (acesso.usuario.tipo == 'FRANQUIA') {
            const administradora = this.utilStorageService.getAdministradora();
            this.listarMenuUsuarioFranquia(administradora.id, acesso.usuario.id);
            this.menuComppleto = [
                {
                    label: administradora.nomeFantasia,
                    icon: 'pi pi-home',
                    items: this.menuObj
                }
            ]
            this.model = this.menuComppleto;
        } else {
            const empresa = this.utilStorageService.getEmpresa();
            this.listarMenuUsuarioEmpresa(empresa.id, acesso.usuario.id);
            this.menuComppleto = [
                {
                    label: empresa.nomeFantasia,
                    icon: 'pi pi-home',
                    items: this.menuObj
                },
                // {
                //     label: 'AMAURI',
                //     items: [
                //         {
                //             label: 'CONTRATOS',
                //             icon: 'pi pi-home',
                //             routerLink: '/'
                //         }
                //     ]
                // }
            ]

            this.model = this.menuComppleto;
        }
    }


    listarMenuUsuarioFranquia(franquiaId?: number, usuarioId?: number) {
        const menus = this.utilStorageService.getMenus();
        if (menus) {
            this.loadingMenus = false;
            this.menusFranquia = menus;
            this.montarMenuFranquia();
        } else {
            this.menuService.listarMenuUsuarioFranquia(franquiaId, usuarioId).subscribe(
                (data) => {
                    this.loadingMenus = false;
                    this.menusFranquia = data;
                    this.montarMenuFranquia();
                    this.utilStorageService.saveMenus(data);
                },
                (error) => {
                    console.error('Error:', error);
                }
            );
        }

    }

    listarMenuUsuarioEmpresa(empresaId?: number, usuarioId?: number) {
        const menus = this.utilStorageService.getMenus();
        if (menus) {
            this.loadingMenus = false;
            this.menusEmpresa = menus;
            this.montarMenuEmpresa();
        } else {
            this.menuService.listarMenuUsuarioEmpresa(empresaId, usuarioId).subscribe(
                (data) => {
                    this.loadingMenus = false;
                    this.menusEmpresa = data;
                    this.montarMenuEmpresa();
                    this.utilStorageService.saveMenus(data);
                },
                (error) => {
                    console.error('Error:', error);
                }
            );
        }
    }

    montarMenuFranquia() {

        if (this.menusFranquia) {
            for (const menuItem of this.menusFranquia) {
                
                if (!menuItem.menuId) {
                    let item = {};
                    !menuItem.url ? item = {
                        id: menuItem.id,
                        posicao: menuItem.posicaoExibicao,
                        label: menuItem.descricao,
                        icon: menuItem.icon,
                        routerLink: menuItem.url,
                        items: []
                    } : item = {
                        id: menuItem.id,
                        posicao: menuItem.posicaoExibicao,
                        label: menuItem.descricao,
                        icon: menuItem.icon,
                        routerLink: menuItem.url
                    }

                    if(!menuItem.url) {
                        item
                    }
                    console.log(item)
                    this.menuObj.push(item);
                }
            }
            for (const menuItem of this.menuObj) {
                let submenus: any[] = [];

                for (const menuFranquia of this.menusFranquia) {
                    if (menuItem.id == menuFranquia.menuId) {
                        const item = {
                            label: menuFranquia.descricao,
                            icon: menuFranquia.icon,
                            routerLink: menuFranquia.url,
                            posicao: menuFranquia.posicaoExibicao,
                        }

                        submenus.push(item);
                        
                    }
                }
                if(submenus.length > 0) {
                    submenus.sort((a, b) => a.posicao - b.posicao);
                    menuItem.items = submenus;
                }
            }

            this.menuObj.sort((a, b) => a.posicao - b.posicao);
        }
    }


    montarMenuFranquia2() {

    
        if (this.menusFranquia) {
            for (const menuItem of this.menusFranquia) {
                const item = {
                    label: menuItem.descricao,
                    icon: menuItem.icon,
                    routerLink: menuItem.url,
                    posicao: menuItem.posicaoExibicao
                }
                this.items.push(item);
                this.items.sort((a, b) => a.posicao - b.posicao);
            }
        }
    }

    montarMenuEmpresa() {
        if (this.menusEmpresa) {
            for (const menuItem of this.menusEmpresa) {
                if (!menuItem.menuId) {
                    let item = {};
                    !menuItem.url ? item = {
                        id: menuItem.id,
                        posicao: menuItem.posicaoExibicao,
                        label: menuItem.descricao,
                        icon: menuItem.icon,
                        routerLink: menuItem.url,
                        items: []
                    } : item = {
                        id: menuItem.id,
                        posicao: menuItem.posicaoExibicao,
                        label: menuItem.descricao,
                        icon: menuItem.icon,
                        routerLink: menuItem.url
                    }
                    this.menuObj.push(item);
                }
            }
            for (const menuItem of this.menuObj) {
                let submenus: any[] = [];

                for (const menuEmpresa of this.menusEmpresa) {
                    if (menuItem.id == menuEmpresa.menuId) {
                        const item = {
                            label: menuEmpresa.descricao,
                            icon: menuEmpresa.icon,
                            routerLink: menuEmpresa.url,
                            posicao: menuEmpresa.posicaoExibicao,
                            isSubmenu: true
                        }
                        submenus.push(item);
                    }
                }

                if(submenus.length > 0) {
                    submenus.sort((a, b) => a.posicao - b.posicao);
                    menuItem.items = submenus;
                }
            }

            this.menuObj.sort((a, b) => a.posicao - b.posicao);
        }
    }

}
